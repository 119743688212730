import CONSTANTS from "config/constants";

const { TITLE } = CONSTANTS;

const en = {
  title: "Chef Choy",
  home: {
    metaTitle: TITLE,
    metaDescription:
      "Your favorite flavors in the Chef Choy sushi menu, you can choose a variety of dishes and snacks prepared with the freshest ingredients.",
    card: {
      download: "Download",
      ourApp: "Our App",
      downloadNow: "Download now",
      useYour: "Use your",
      couponsOf: "coupons of",
      discount: "discount",
      discounts: "discounts",
      coupons: "Coupons",
      enjoy: "Enjoy",
      specials: "and specials"
    },
    menu: "Show menu",
    promotions: "Promotions",
    superPromotions: "Super promotions"
  },
  selectAddress: {
    deliverIn: "Delivery in",
    pickupAt: "Pickup at",
    selectAddress: "Select a address",
    selectStore: "Select a store",
    currentLocation: "Use current location",
    nearestAddress: "Choose nearest address",
    storeNearest: "Select store nearest you",
    addNewAddress: "Add new address"
  },
  productsSearch: {
    products: "Products",
    metaTitle: "Discover",
    recentSearches: "Recent searches",
    clearHistory: "Clear history",
    popularSearches: "Popular searches",
    results: "Results",
    resultsOf: "Results of",
    discoverProducts: "Discover more products",
    noResultsFor: "There are no results for",
    recommended: "Recommended",
    seeMenu: "See complete menu",
    noFind: "Didn't find what you wanted?",
    errorLoading:
      "There was an error loading the page, try reloading the page or come back later"
  },
  loyalty: {
    metaTitle: "Loyalty"
  },
  coupons: {
    metaTitle: "Coupons",
    detailTitle: "Coupons",
    title: "Digital coupons",
    apply: "Apply",
    applyInCart: "Apply the coupon to the cart",
    available: "Available to redeem",
    noCoupons: "There are no coupons available for your account.",
    enterWithYourAccount: "Enter with your account to redeem your coupons",
    signIn: "Sign in",
    registerAndGetBenefits: "Register and get benefits",
    getCoupons:
      "Get discount coupons, save your data for future purchases and much more",
    enter: "Enter with your account",
    couponWallet: "Coupon wallet",
    couponsBenefits: "With your coupons you can get discounts and benefits.",
    addNewCoupon: "Add new coupon",
    addCoupons: "Add coupons",
    couponsInfo: "With coupons you can get discounts and benefits.",
    noCouponsToApply: "You do not have coupons to apply",
    addCoupon: "Enter your coupon",
    validUntil: "Valid until",
    redeemSuccess: "Coupon redeemed successfully"
  },
  profile: {
    profile: "Profile",
    metaTitle: "User",
    completeProfile: "Complete profile",
    greeting: "Hello",
    welcome: "Welcome",
    edit: "Edit account",
    enjoyBenefits: "Sign in to enjoy the benefits of having an account",
    orderHistory: {
      orderNow: "Order now",
      purchaseHistory: "Purchase history",
      firstPurchase: "Make your first purchase",
      explore: "Explore",
      exploreAllProducts: "Explore all our products and categories",
      date: "Date",
      pastOrders: "Past orders",
      filters: {
        last15Days: "last 15 days",
        lastMonth: "last month",
        last6Months: "last 6 months"
      }
    },
    navigation: {
      orderHistory: "Order history",
      coupons: "Coupons",
      deliveryAddress: "Delivery address",
      stores: "Stores",
      billing: "Billing",
      notifications: "Notifications",
      legalTerms: "Legal terms",
      support: "Support",
      signOut: "Sign out"
    },
    notifications: {
      title: "Notifications",
      description: "I accept to receive email notifications",
      consult: "Consult the status in",
      orders: "My orders",
      receiveNotifications: "Receive notifications of the status of my orders"
    },
    address: {
      addNewAddress: "Add new address",
      house: "House",
      office: "Office",
      others: "Others",
      predetermined: "Predetermined",
      findAddress: "Find address on the map",
      findYourAddress: "Find your address",
      placeHolderSearch: "Find an address",
      confirmLocation: "Confirm location",
      currentPosition: "Your current position",
      noCoverage: "Out of cover",
      noCoverageDescription:
        "Your address is out of coverage, try to change it",
      editLocation: "Edit location",
      fillAddress: "Fill in your address",
      noResultsFound: "No results found",
      saveAddress: "Save your address",
      descriptionEmptyAddresses:
        "Add a delivery address for making your purchases.",
      deleteAddress: "Do you want to delete?",
      deleteAddressDescription:
        "Once deleted you will not be able to recover this information",
      form: {
        saveAddress: "Save address",
        labelName: "Label",
        house: "House",
        work: "Work",
        others: "Others",
        usePredetermined: "Save as predetermined",
        label: {
          mainStreet: "Main Street",
          houseNumber: "House number",
          secondaryStreet: "Secondary street",
          reference: "Reference",
          phoneNumber: "Phone number for confirmation"
        },
        placeholder: {
          mainStreet: "Av Orellana",
          houseNumber: "E3 40",
          secondaryStreet: "Av Amazonas",
          reference: "Metropolitan Park / Ecuanimsa",
          phoneNumber: "99 999 9999",
          others: "Mi house",
          comment: "Delivery instructions for this address"
        }
      }
    },
    stores: {
      title: "Stores",
      open: "Open",
      closed: "Closed",
      address: "Address",
      phone: "Phone",
      opens: "Opens",
      closing: "Closing",
      schedules: "Schedules"
    },
    support: {
      title: "Contact support",
      description: "Contact our support channels to receive help",
      email: "Email",
      whatsapp: "Whatsapp",
      phone: "Phone"
    },
    user: {
      identityCard: "Cédula",
      passport: "Passport",
      ruc: "RUC",
      deleteAccount: "Delete account",
      saveChanges: "Save changes",
      connectGoogle: "Google connect",
      connectFacebook: "Facebook connect",
      disconnectGoogle: "Disconnect from Google",
      disconnectFacebook: "Disconnect from Facebook",
      connectNetworks: "Connect your accounts with your networks",
      leavingUs: "Why are you leaving us?",
      tellUsMore: "Tell us more",
      byeDifficult: "Saying goodbye is difficult",
      unsubscribeDescription1: "We are sorry to see you go.",
      unsubscribeDescription2: "Tell us why you decided to leave us.",
      unsubscribeDescription3:
        "Once you delete your account these will be the consequences",
      reason1: "I had a bad experience",
      reason2: "No coverage in my area",
      reason3: "Someone else is using my account",
      reason4: "Consumes a lot of data",
      reason5: "I do not use the application",
      unlinkAccount: "Unlink account",
      sureUnlinkAccount: "Are you sure you want to unlink your account?",
      dataMayBe:
        "It is possible that some data may be lost when doing this action",
      maintainData: "Maintain data",
      enterEmailConfirm: "Enter your email to confirm",
      noRecoverData:
        "Once your account has been deleted you will not be able to recover your data.",
      maintainAccount: "Maintain account",
      deleteForever: "Delete my data forever",
      keepEnjoying: "Follow the rollers",
      thankYouTrust: "Keep enjoying Chef Choy. ",
      accountDeleted: "Your account has been deleted",
      lookAgain: "We look forward to seeing you again",
      errors: {
        noDeleteAccount:
          "It was not possible to delete the account. Please try again later",
        phone: "The phone number entered is invalid",
        birthdate: "User must be over 18 years old"
      }
    }
  },
  billing: {
    searchBillingInformation: "Search billing information",
    billingInformation: "Billing information",
    addBillingInformation: "Add new billing information",
    editBillingInformation: "Edit billing information",
    newBillingInformation: "New billing information",
    deleteBillingInformation:
      "Once deleted you will not be able to recover this information",
    questionDeleteBillingInformation: "Do you want to proceed to delete?",
    saveYourData: "Save your data",
    saveYourDataDescription:
      "Save your billing information to make your purchases",
    youWantPreviousData:
      "Do you want to use the data from your previous purchases?",
    importData: "Import data",
    continueWithoutData: "Continue without import data"
  },
  terms: {
    legalTerms: "Legal terms",
    privacy: "Privacy Policy",
    data: "Data Protection Policy",
    dataProtection: {
      title: "We invite you to review our new policies and accept them",
      description: {
        first:
          "We handle the information you provide us to manage our business initiatives and maintain a close relationship with our customers.",
        second: (email: string) =>
          `We want you to know that you have the right to access, update, revoke, delete and oppose the processing of your data. If you wish to exercise any of these rights, do not hesitate to send us an email to: ${email}. We will be happy to help you with whatever you need.`
      }
    }
  },
  navigation: {
    shopping: "Shopping",
    discover: "Discover",
    loyalty: "Loyalty",
    coupons: "Coupons",
    profile: "Menu"
  },
  navbar: {
    enter: "Login",
    recentSearches: "Recent searches",
    cleanHistory: "Clean history",
    popularSearches: "Popular searches",
    results: "results"
  },
  catalogues: {
    delivery: "Delivery",
    pickup: "Pick-Up",
    other: "Other"
  },
  geo: {
    useCurrentLocation: "Use my current location",
    selectNearbyStore: "Select nearby store"
  },
  common: {
    enter: "Enter",
    whatDoYouProvokeToday: "What are you craving today?",
    pickupIn: "Pick-Up in",
    deliveryIn: "Delivery in",
    selectAddress: "Select address",
    selectStore: "Select store",
    search: "Search",
    cart: "Cart",
    edit: "Edit",
    preferences: "Preferences",
    workflows: "Purchase method",
    apply: "Apply",
    accept: "Accept",
    buyingIn: "Buying in",
    catalogue: "Catalogue",
    termsAndConditions:
      "I have red and accept the privacy policies, terms and conditions",
    preTerms: "I have read and accept the",
    midTerms: "Chef Choy's use terms",
    postTerms: "to use this platform",
    orderNow: "Order now",
    copyright: "ⓒ Chef Choy. All rights reserved.",
    company: "Trade",
    order: "Order",
    help: "Ayuda",
    see: "See",
    seeNow: "See now",
    repeatOrder: "Repeat order",
    repeat: "Repeat",
    state: "State",
    details: "Details",
    add: "Add",
    delete: "Delete",
    change: "Change",
    auth: {
      signInWithGoogle: "Access with Google",
      signInWithFacebook: "Access with Facebook",
      signInWithEmail: "Access with email",
      signIn: "Sign In",
      signUp: "Sign Up",
      forgotPassword: "Do you forgot your password?",
      recoverPassword: "Recover my password",
      weRecognizeYou: "We recognize you!",
      importingData: "We are importing your data."
    },
    goToProduct: "Go to product",
    backTo: "Back to",
    summary: "Summary",
    forms: {
      label: {
        email: "Email",
        password: "Password",
        name: "Name",
        lastName: "Last Name",
        phone: "Phone",
        birth: "Birth date",
        payWith: "How much are you going to pay with?",
        comment: "Do you want to add a note for the local?",
        documentType: "Document type",
        documentNumber: "Document number",
        businessName: "Business name",
        nameAndLastName: "Name and last name",
        useSaveDirection: "Use saved direction?",
        useLikeDefault: "Use as default",
        selectAnAddress: "Select an address",
        address: "Address",
        selectAddress: "Select Address"
      },
      placeholder: {
        email: "juanperez@gmail.com",
        password: "*************",
        name: "David",
        lastName: "Fonseca",
        phone: "99 999 2323",
        comment: "Write your message here",
        address: "Av Orellana 130",
        documentNumber: "ZE001355"
      },
      errors: {
        invalidPhone: "The phone number is not valid",
        emailNotMatch: "The email does not match the registered one",
        alreadyImported:
          "It looks like we've already imported your data. Sign into your account to continue."
      }
    },
    saveBillingData: "Save billing data",
    goBack: "Go back",
    continue: "Continue",
    cancel: "Cancel",
    send: "Send",
    resend: "Resend",
    and: "and",
    from: "from",
    to: "to"
  },
  footer: {
    termsAndConditions: "Terms and conditions",
    privacyPolicy: "Privacy policy",
    faq: "Frequently asked questions",
    about: "Work with us",
    location: "Where are we?",
    reservations: "Reservations",
    developer: "Developed by"
  },
  order: {
    today: "Today",
    currentOrder: "Order in progress",
    completedOrder: "Order completed",
    processing: "Processing your order",
    success: "Order successfully processed",
    error: "There was a problem processing your order",
    tryAgain: "Try again",
    emptyCart: "You have not yet added products to your cart",
    exploreMenuDescription: "Explore our menu and add products!",
    exploreMenu: "Explore menu",
    transactionInformation: "Transaction information",
    qualifyOrder: "Qualify your order",
    qualify: "Calificar",
    howOrder: "How was your order?",
    order: "Order",
    qualifiedOrder: "Qualified order",
    noExistOrder: "There is no order in this session",
    noExistOrderNavigator:
      "There is no information for the specified order in this navigator",
    estimatedTime: "Estimated time of delivery",
    negativeFeedback: "Let us know what went wrong",
    positiveFeedback: "Tell us what you liked",
    wentWrong: "Did something go wrong?",
    whatLike: "What did you like the most?",
    thankFeedback: "Thank you for your comments",
    savedTime: "Save time on your purchases",
    savedTimeDescription:
      "Sign in with your account and make your purchases without re-filling your data",
    orderNumber: "Order number",
    orderTime: "Timestamp",
    orderCode: "Order code",
    reference: "Reference",
    state: "State",
    subTotal: "Subtotal",
    summary: "Order summary",
    sending: "Sending",
    total: "Total",
    taxes: "Impuestos",
    discount: "Discounts",
    updateOrder: "Update",
    destination: "Destination",
    store: "Store",
    paymentMethod: "Payment method"
  },
  checkout: {
    title: "Checkout",
    wantBuyFast: "Want to order faster?",
    signInWithYourAccount: "Login with your account",
    shippingAddress: "Shipping Address",
    pay: "Pay",
    completeYourShippingAddress: "Complete your shipping address",
    completeYourBillingData: "Complete your billing address",
    billingData: "Billing Data",
    endConsumer: "End consumer",
    addBillingData: "Add billing data",
    anonymousForm: {
      purchaseData: "Purchase data",
      useSameDataForBilling: "Use same data for billing"
    },
    paymentMethods: "Payment methods",
    cash: "Cash",
    card: "Card",
    payWithExactChange: "Pay with exact change",
    change: "Change:",
    moreThan50: "Cart value greater than $50 must include bill",
    identityBox: "ID number",
    processingPayment: {
      title: "Processing your payment now...",
      subtitle: "This could take some time..."
    }
  },
  errors: {
    paymentez: {
      cannotContinue: "Cannot continue with your payment",
      errorWhilePayOrder: "An error occurred while paying your order",
      failure: "Your purchase attempt has failed",
      refused: "Purchase refused",
      notTypeOfSuccessResponse: "Your card is not valid",
      saveCard: "We had a problem saving your card",
      card: "You do not have a card to be saved",
      store: "You do not have a selected store",
      transactionId: "We did not found the transaction id",
      otpPending: "The OTP code entered is invalid, please try again.",
      otpFailure: "There was a problem verifying your card",
      unsupportedIssuer: "Selected issuer is not supported",
      unsupportedType: "Selected type is not supported",
      invalidFields: "Invalid fields",
      invalidExpirationDate: "Invalid expiration date"
    },
    amountIsNotEnough: "The amount is not enough",
    unsavedData: "Your data could not be saved",
    completeYourDataProfile: "Complete your data again in your profile",
    registrationError: "There was an error while registering",
    alreadyAssociated:
      "This email is already associated with another user account",
    firebase: {
      auth: {
        wrongPassword: "Invalid data",
        userNotFound:
          "There is no existing user record corresponding to the provided identifier",
        tooManyRequests:
          "Too many requests. The access to this account has been temporally disabled",
        emailAlreadyInUse:
          "The provided email is already in use by an existing user",
        networkRequestFailed: "A network error has occurred, try again",
        operationNotAllowed:
          "The provided sign-in provider is disabled for your Firebase project",
        accountExistsWithDifferentCredential:
          "An account already exists with the same email address but different sign-in credentials",
        invalidActionCode: "The used link is invalid",
        googleProvider: "No user was found with Google provider",
        facebookProvider: "No user was found with Facebook provider"
      }
    },
    geo: {
      noCoordinates: "No coordinates were found"
    },
    stores: {
      noStores: "No stores in these bounds",
      storeClosed: "Store closed"
    },
    fallback: "An error has occurred, try again",
    common: "Ups something went wrong",
    productError: "There was an error in the products",
    general: "Oops something went wrong",
    back: "Go back",
    acceptTerms: "You must accept the terms of use before continuing",
    coupons: {
      invalid: "Coupon is invalid",
      noProducts:
        "Can't apply benefit because there are no products in the cart",
      exceedsAmount:
        "Cannot apply benefit because discount is greater than cart subtotal."
    },
    card: {
      failAddCard: "We had a problem adding your card",
      maxCardLimit: (maxCards: number) =>
        `You can only have ${maxCards} active cards`,
      overCardLimit: "You have exceeded the limit of active cards",
      noSelectedCard: "You have to select at least one card to continue"
    },
    checkout: {
      insufficientFunds: "Insufficient funds",
      missingData: (data: string[]) =>
        `Order couldn't be placed due to missing data: ${data.join(", ")}`,
      invalidBilling: "Entered billing data is invalid",
      noShippingAddress: "You must select a shipping address",
      timeout: {
        title: "It's taking a while to update your order",
        subtitle: "Your order may already be processed.",
        body: "Wait a few minutes and check your order history to confirm if your order was created",
        button: "Check order history"
      }
    },
    maxAddressLimit: (maxAddress: number) =>
      `You can only have ${maxAddress} active addresses`,
    maxBillingLimit: (maxBilling: number) =>
      `You can only have ${maxBilling} active billing data`,
    overAddressLimit: "You have exceeded the limit of active addresses",
    updateAddress: "An error occurred while updating the address",
    deleteAddress: "An error occurred while deleting the address",
    overBillingLimit: "You have exceeded the limit of active billing data",
    updateBilling: "An error occurred while updating the billing data",
    deleteBilling: "An error occurred while deleting the billing data",
    showMapAddress: "There was an error showing the address on the map",
    failSearch: "Search results could not be loaded",
    fetchAddress: "Address could not be loaded",
    password: {
      changeSuccess: "Change was successfully applied",
      updateSuccess: "Password successfully updated",
      updateFail: "Password could not be updated"
    },
    minOrderAmount: (minAmount: number) =>
      `The minimum order amount is $${minAmount}`,
    minOrder: "Your order does not meet the minimum amount",
    maxOrderAmount: (maxAmount: number) =>
      `The maximum order amount is $${maxAmount}`,
    maxOrder: "Your order exceeds the maximum amount",
    unsupportedProvider: (provider: any) =>
      `We do not accept payments from provider ${provider}`,
    pendingFetchError: "An error occurred while fetching the pending orders",
    user: {
      saveError: "An error occurred while saving user data",
      updateError: "An error occurred while updating user data"
    },
    documentIsEmpty: "Document cannot be empty"
  },
  productDetail: {
    utensils: "Utensils",
    onlyCanDecrease: "You cannot increase, only decrease"
  },
  success: {
    resendEmail: "Email successfully forwarded",
    login: "Login successfully"
  },
  signIn: {
    signInUse: "Sign in use",
    signInWithEmail: "Sign in with email",
    haveNotAnAccountYet: "Have not an account yet?",
    title: "Sign in to your account",
    signInUseEmail: "Enter your email"
  },
  signUp: {
    signUpUse: "Sign up use",
    signUpWithEmail: "or sign up with email",
    alreadyHaveAnAccount: "Already have an account?",
    title: "Enjoy the benefits of having an account",
    acceptThe: "I accept the",
    terms: "terms and conditions",
    privacy: "privacy policy",
    byCompany: "by Chef Choy"
  },
  magicLink: {
    description: "We send an email for to enter your account"
  },
  checkEmailVerification: {
    title: "We have send an email to:",
    instructions:
      "There you will find a link that will allow you log in to your account",
    help: "Have you not received the entry email?",
    tryAgain: "Send again"
  },
  signUpUser: {
    title: "One more step",
    subtitle: "Is important that you complete your data for create an account",
    modal: {
      title: "Take into account the following",
      description:
        "If you continue you will lose the data associated with your identifier"
    }
  },
  repeatOrder: {
    title: "Eager to relive moments?",
    repeatOrder: "Repeat last order",
    placeholder: {
      description: "Repeat your order from",
      order: "Order:"
    }
  },
  recover: {
    title: "Enter your email to recover your password",
    doNotReceiveEmail: "Didn't you get the mail?",
    retryIn: "Try again on ",
    wrongEmail: "Wrong mail?",
    editEmail: "Edit email",
    checkSpam:
      "Your email was resent again, if you don't get it try looking in the spam folder.",
    weSendEmail: "We have sent an email to",
    checkEmail: "check and sign in."
  },
  password: {
    title: "The password must contain:",
    validation: {
      number: "At least one number",
      specialChar: "At least one special character",
      capitalLetter: "At least one capital letter",
      minLength: "At least eight characters"
    }
  },
  cart: {
    cart: "Cart",
    add: "Add",
    share: "Share",
    goCheckout: "Ir a pagar",
    emptyCart: "Empty cart",
    emptyState: "You do not have any products\nin your cart yet",
    emptyDescription: "Add products to start a new purchase",
    addProducts: "Agregar productos",
    productNotAvailable: "Product not Available",
    addedProduct: "You have added a product",
    showCart: "Show cart",
    packagingCosts: "Packaging costs",
    includePackageCost: "Does not include packaging costs",
    couponLoginTitle: "Sign up for benefits",
    couponLoginDescription:
      "By registering you will be able to make use of our coupons and get many benefits."
  },
  moveCart: {
    title: "Your cart products",
    message: "You could lose some of your products when changing address",
    confirm: "Create new cart",
    cancel: "Keep my cart",
    success: "Your cart has been moved to the new location"
  },
  rebuildCart: {
    title: "Your cart products",
    message: "You could lose some of your products when rebuilding your cart",
    confirm: "Rebuild cart",
    cancel: "Keep my cart",
    success:
      "Your cart has been rebuilt with the products from your anonymous user"
  },
  product: {
    writeComment: "Escribe un comentario",
    aComment: "Un comentario"
  },
  notifications: {
    outCoverage: "You are out of coverage",
    changeLocationContinue: "Try changing your location to continue",
    changeLocation: "Change location"
  },
  fourZeroFour: {
    title: "Error 404",
    description: "Page not found!",
    button: "Go to home"
  },
  shoppingCart: {
    maximum: "Maximum",
    utensil: "utensil",
    sauce: "sauce",
    findExtraProducts:
      "*If you want additional sauces, you can buy them in the section of",
    extras: "EXTRAS"
  },
  paymentezModal: {
    verifyCard: "Verify your card",
    verifyCode: "Verify code"
  },
  card: {
    selectCard: "Select card",
    myCard: "My card",
    myCards: "My cards",
    addCard: "Add card",
    changeCard: "Change card",
    listTitle: "Save your cards",
    listDescription: "Save a new card to make your purchases even faster.",
    cardHolder: "Card holder",
    cardHolderPlaceholder: "John Doe",
    cardNumber: "Card number",
    expirationDate: "Expiration date",
    cvv: "CVV",
    saveCard: "Save card"
  }
};

export default en;
